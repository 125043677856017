// Import scripts
import { Fancybox } from '@fancyapps/ui'; // eslint-disable-line
// import './vendor/slick.min';
// Styles
import '../sass/main.scss';

// Import asset images
// import './images';

// Import javascript
// import debounce from './helpers/debounce';

// import siteFocus from './partials/siteFocus';
import sliders from './components/sliders';
import hero from './components/videos/hero';

document.addEventListener("DOMContentLoaded", function(event) {
  sliders();
  hero();

  Fancybox.bind("[data-fancybox]", {
    on: {
      close: (fancybox, slide) => {
        let heroVideo = document.querySelector('#rb-hero video');

        if (heroVideo && heroVideo.paused)
          heroVideo.play();
      },
    }
  });
});