import Swiper from 'swiper';
import { Navigation, EffectCards, Controller } from 'swiper/modules';


export default () => {
  (() => {
    const swiper = new Swiper( '.js-swiperSteps', {
      modules: [Navigation],
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    } );
  })();

  (() => {
    var swiperImage = new Swiper( '.js-swiperTestImages', {
      modules: [Navigation, EffectCards, Controller],
      effect: "cards",
      watchSlidesProgress: true,
      direction: 'vertical',
      preventClicks: true,
      draggable: false,
      cardsEffect: {
        perSlideOffset: 10,
        grabCursor: true,
        rotate: false,
      },

      breakpoints: {
        768: {
          preventClicks: false,
          draggable: true,
          direction: 'horizontal'
        }
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    } );

    var swiperText = new Swiper( '.js-swiperTestText', {
      modules: [Navigation, Controller],
      slidesPerView: 1,
      spaceBetween: 30,
      effect: 'fade',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    } );

    swiperText.controller.control = swiperImage;
    swiperImage.controller.control = swiperText;
  })();
}